<template>
  <div>
    <el-menu :default-active="activeIndex" class="flex" mode="horizontal" @select="handleSelect"
      active-text-color="#409EFF" text-color="#333333">
      <el-image class="logo_img_cla"
        :src="'https://www.aigehui028.com/static/mp/images/agh-img/index/logo.png'"></el-image>
      <el-menu-item index="1">首页</el-menu-item>
      <el-submenu index="2">
        <template slot="title">关于我们</template>
        <el-menu-item index="2-gsjjDiv">公司简介</el-menu-item>
        <el-menu-item index="2-qywhDiv">企业文化</el-menu-item>
        <el-menu-item index="2-tdbjDiv">团队背景</el-menu-item>
        <el-menu-item index="2-qyryDiv">企业荣誉</el-menu-item>
      </el-submenu>
      <el-menu-item index="3">产品中心</el-menu-item>
      <el-menu-item index="4">案例医院</el-menu-item>
      <el-menu-item index="5">新闻资讯</el-menu-item>
      <el-menu-item index="6">招募创业股东</el-menu-item>
      <el-menu-item index="7">城市合伙人</el-menu-item>
      <el-menu-item index="8">免费投放</el-menu-item>
      <el-menu-item index="9">联系我们</el-menu-item>
      <el-image class="phone_img_cla"
        :src="'https://www.aigehui028.com/static/mp/images/agh-img/index/phone.png'"></el-image>
      <span style="font-size: 18px;">400-888-9582</span>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "TopMenu",
  data() {
    return {
      activeIndex: '1',
      menuRoutes: {
        '1': '/',
        '2': '/aboutUs',
        '3': '/productCenter',
        '4': '/hospital',
        '5': '/newsInformation',
        '6': '/shareHolder',
        '7': '/cityPartner',
        '8': '/freePlacement',
        '9': '/contactUs'
      },
    };
  },
  watch: {
    //为每一个菜单配置一个mainMenuIndex作为当前路由的激活菜单，用watch监听
    '$route'(newRoute) {
      const mainMenuIndex = newRoute.meta.mainMenuIndex;
      if (mainMenuIndex) {
        this.activeIndex = mainMenuIndex;
      }
    }
  },
  methods: {
    handleSelect(key) {
      console.log('key', key);
      var index = key.split("-")[0];
      var div = key.split("-")[1];
      // 如果选中的菜单项与当前激活的菜单项相同，并且没有指定div，则重新加载页面
      if (index == this.activeIndex && !div) {
        return
      } else {
        // 更新当前激活的菜单项
        this.activeIndex = index.toString();
        // 将当前激活的菜单项保存到localStorage中
        localStorage.setItem("menuIndex", this.activeIndex);
        const path = this.menuRoutes[index]
        if (path) {
          this.$router.push(path).then(() => {
            // 路由跳转成功后的操作
            if (div) {
              // 确保路由跳转完成后才更新hash
              this.$nextTick(() => {
                location.hash = '#' + div;
              });
            }
          }).catch(err => {
            // 处理错误，通常是重复导航到相同的路由
            console.error(err);
          });
        }
      }
    },
  },
  created() {
    var rou = this.$router;
    console.log(rou);
    for (const [index, path] of Object.entries(this.menuRoutes)) {
      if (path === this.$route.path) {
        this.activeIndex = index;
        break;
      }
    }
    var index = localStorage.getItem("menuIndex");
    if (index && this.menuRoutes[index] === this.$route.path) {
      this.activeIndex = index;
    } else {
      localStorage.setItem("menuIndex", this.activeIndex);
    }
  }
}
</script>

<style scoped>
.el-menu-item {
  font-size: 18px;
}

.el-menu /deep/ .el-submenu__title {
  font-size: 18px !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_img_cla {
  height: 48px;
  margin-right: 30px;
}

.phone_img_cla {
  width: 20px;
  height: 20px;
  margin: 0 5px 0 30px;
}
</style>