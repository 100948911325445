import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui"; //引入ElementUI
import "element-ui/lib/theme-chalk/index.css"; //导入样式
import router from "./router";
import Meta from "vue-meta";
import store from "./store/index";
Vue.use(ElementUI);
// Vue.use(VueMeta);
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});
//使用
//引入公共的顶部菜单
import topMenu from "./pages/commons/TopMenu";
Vue.component("topMenu", topMenu);
//引入页面公共底部页面
import bottomPage from "./pages/commons/BottomPage";
Vue.component("bottomPage", bottomPage);
//公用样式
import "./static/css/commons.css";
import "./static/css/hover.css";

Vue.config.productionTip = false;

//每次路由更新前，设置当前页面的meta信息
router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) {
    store.commit("CHANGE_META_INFO", to.meta.metaInfo);
  }
  next();
});
new Vue({
  router,
  store,
  metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: this.$store.state.metaInfo.keywords,
        },
        {
          name: "description",
          content: this.$store.state.metaInfo.description,
        },
      ],
    };
  },
  render: (h) => h(App),
}).$mount("#app");
// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");
