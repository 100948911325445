import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// 用来存储数据
const state = {
  metaInfo: {
    title: "1212",
    keywords: "1212",
    description: "1212",
  },
};
// 响应组件中的事件
const actions = {};
// 操作数据
const mutations = {
  CHANGE_META_INFO(state, metaInfo) {
    state.metaInfo = metaInfo;
  },
};
// 用来将state数据进行加工
const getters = {};
// 新建并暴露store
export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
});
