import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// 创建路由实例并配置路由映射
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/index",
      component: () => import("@/pages/Index"), //路由懒加载
      meta: {
        metaInfo: {
          title: "【爱格汇】共享陪护床 共享轮椅免费投放-爱格汇集团",
          keywords: "爱格汇,共享陪护床,共享轮椅,爱格汇集团",
          description:
            "四川爱格汇科技集团有限公司是国内专注于共享陪护及医院信息服务一体化的高新技术企业。公司始终致力于健康产业的开发和创新，通过科学创新，改变传统的医疗环境与人们的医疗体验。",
        },
      },
    },
    {
      path: "/index",
      component: () => import("@/pages/Index"), //路由懒加载
      meta: {
        metaInfo: {
          title: "【爱格汇】共享陪护床 共享轮椅免费投放-爱格汇集团",
          keywords: "爱格汇,共享陪护床,共享轮椅,爱格汇集团",
          description:
            "四川爱格汇科技集团有限公司是国内专注于共享陪护及医院信息服务一体化的高新技术企业。公司始终致力于健康产业的开发和创新，通过科学创新，改变传统的医疗环境与人们的医疗体验。",
        },
      },
    },
    //关于我们
    {
      path: "/aboutUs",
      component: () => import("@/pages/AboutUs"), //路由懒加载
      meta: {
        mainMenuIndex: "2",
        metaInfo: {
          title: "关于我们-爱格汇科技集团",
          keywords: "爱格汇公司,爱格汇企业,爱格汇简介",
          description:
            "爱格汇集团拥有雄厚的技术团队、专业的管理能力、生产设备以及国内领先的研发团队，所有团队成员均拥有高学历、多经验的特点，能够满足各类客户的需求，公司始终坚持以研发与服务为核心导向。",
        },
      },
    },
    //产品中心
    {
      path: "/productCenter",
      component: () => import("@/pages/product/ProductCenter"), //路由懒加载
      meta: {
        mainMenuIndex: "3",
        metaInfo: {
          title: "产品中心-爱格汇科技集团",
          keywords: "爱格汇产品,爱格汇业务,爱格汇项目",
          description:
            "公司始终坚持以研发与服务为核心导向，发展医院信息一体化服务及传媒业务，用户可通过平台进行病例、报告的查看，费用的支付，享受生活用品、治疗用药、营养膳食等院内配送到位的服务。",
        },
      },
    },
    {
      path: "/bed",
      component: () => import("@/pages/product/Bed"), //路由懒加载
      meta: {
        mainMenuIndex: "3",
        metaInfo: {
          title: "【爱格汇】共享陪护床 共享住院床免费投放-爱格汇集团",
          keywords: "爱格汇共享陪护床,共享陪护床,共享住院床,爱格汇陪护床",
          description:
            "爱格汇科技集团共享陪护床具有投放灵活、安装简单、通电即可自动杀毒，还床后紫外线+臭氧360度全面杀菌，按时计费可远程付费解锁，多种付费方式支持支付宝、微信信用分免押金租借。",
        },
      },
    },
    {
      path: "/bedside",
      component: () => import("@/pages/product/BedSide"), //路由懒加载
      meta: {
        mainMenuIndex: "3",
        metaInfo: {
          title: "【爱格汇】共享床头柜 住院床头柜设备投放-爱格汇集团",
          keywords: "爱格汇共享床头柜,共享床头柜,住院床头柜,爱格汇床头柜",
          description:
            "爱格汇科技集团共享床头柜安装简单、通电即可，投放灵活，可自由组装，节能用电，高回弹海绵+硬板结构，坐感硬中带柔，不易塌陷，后台实时监管，数据详细，订单清晰可见，运营无忧。",
        },
      },
    },
    {
      path: "/wheelchair",
      component: () => import("@/pages/product/Wheelchair"), //路由懒加载
      meta: {
        mainMenuIndex: "3",
        metaInfo: {
          title: "【爱格汇】共享轮椅 医院扫码轮椅免费投放-爱格汇集团",
          keywords: "爱格汇共享轮椅,共享轮椅,共享扫码轮椅,爱格汇轮椅",
          description:
            "爱格汇科技集团优化医院轮椅管理，共享轮椅统一地点放置随取随用，省时省力，无需专人登记管理，用户扫码租借，随借随还，使用方便，提升医院医疗服务，人性化管理，提升医患和谐关系。",
        },
      },
    },
    //新闻资讯
    {
      path: "/newsInformation",
      component: () => import("@/pages/news/newsInformation"), //路由懒加载
      meta: {
        mainMenuIndex: "5",
        metaInfo: {
          title: "新闻资讯-爱格汇科技集团",
          keywords: "爱格汇新闻,爱格汇资讯,爱格汇热点",
          description:
            "爱格汇集团将继续保持着初心与使命，探索共享陪护市场，不断完善技术水平，为各大医院里的老年人、孕妇、残疾人、手术患者等腿脚不便人士提供便利舒适的高性价比共享医护产品。",
        },
      },
    },
    //新闻详情
    {
      path: "/newsDetail",
      component: () => import("@/pages/news/newsDetail"), //路由懒加载
      meta: {
        mainMenuIndex: "5",
        metaInfo: {
          title: "新闻资讯-爱格汇科技集团",
          keywords: "爱格汇新闻,爱格汇资讯,爱格汇热点",
          description:
            "爱格汇集团将继续保持着初心与使命，探索共享陪护市场，不断完善技术水平，为各大医院里的老年人、孕妇、残疾人、手术患者等腿脚不便人士提供便利舒适的高性价比共享医护产品。",
        },
      },
    },
    //案例医院
    {
      path: "/hospital",
      component: () => import("@/pages/hospital/Hospital"), //路由懒加载
      meta: {
        mainMenuIndex: "4",
        metaInfo: {
          title: "案例医院-爱格汇科技集团",
          keywords: "爱格汇案例,爱格汇伙伴,爱格汇合作",
          description:
            "爱格汇集团招投标部门屡创佳绩，经过各部门同事通力协作，成功中标各大医院共享陪护床及轮椅项目。每一次中标都代表着凭借专业的技术设备、完善的售后服务和合理的报价赢得了医院和大众的一致认可。",
        },
      },
    },
    //案例医院内页
    {
      path: "/hospitalDetail",
      component: () => import("@/pages/hospital/Detail"), //路由懒加载
      meta: {
        mainMenuIndex: "4",
        metaInfo: {
          title: "案例医院-爱格汇科技集团",
          keywords: "爱格汇案例,爱格汇伙伴,爱格汇合作",
          description:
            "爱格汇集团招投标部门屡创佳绩，经过各部门同事通力协作，成功中标各大医院共享陪护床及轮椅项目。每一次中标都代表着凭借专业的技术设备、完善的售后服务和合理的报价赢得了医院和大众的一致认可。",
        },
      },
    },
    {
      path: "/shareHolder",
      component: () => import("@/pages/ShareHolder"), //路由懒加载
      meta: {
        mainMenuIndex: "6",
        metaInfo: {
          title: "招募企业股东-爱格汇科技集团",
          keywords: "爱格汇股东,爱格汇合伙人,加入爱格汇",
          description:
            "爱格汇集团共享医护项目是目前共享经济中深受投资者喜爱的项目，很多人选择加盟共享医护行业，或成为爱格汇集团的企业股东，目前该项目已经成为了2024年十大热门共享加盟项目的推荐名单里。",
        },
      },
    },
    {
      path: "/cityPartner",
      component: () => import("@/pages/CityPartner"), //路由懒加载
      meta: {
        mainMenuIndex: "7",
        metaInfo: {
          title: "城市合伙人-爱格汇科技集团",
          keywords: "爱格汇加盟,爱格汇招商,爱格汇合作",
          description:
            "爱格汇集团共享医护品类是国内为数不多的在行业中具有核心竞争力的优秀企业。凭借经验丰富的管理团队以及完善的服务流程，公司确保可以给客户提供一流的技术支持与专业服务。",
        },
      },
    },
    {
      path: "/freePlacement",
      component: () => import("@/pages/FreePlacement"), //路由懒加载
      meta: {
        mainMenuIndex: "8",
        metaInfo: {
          title: "共享陪护床【轮椅】免费投放-爱格汇科技集团",
          keywords: "共享陪护床免费投放,共享轮椅免费投放",
          description:
            "爱格汇集团提出的联营合伙人计划，合伙人能够通过洽谈与医院达成合作，在合伙人提供的医院里面，将免费投放设备，这样合伙人不需要出一分钱就能够成功达成共享陪护床项目的投资。",
        },
      },
    },
    {
      path: "/contactUs",
      component: () => import("@/pages/ContactUs"), //路由懒加载
      meta: {
        mainMenuIndex: "9",
        metaInfo: {
          title: "联系我们-爱格汇科技集团",
          keywords: "爱格汇电话,爱格汇地址,爱格汇在哪",
          description:
            "爱格汇集团公司将坚持以科技创新为理念，以发展医疗服务产业为己任，打造全方位一站式医院陪护服务体系。用更智能、更安全的方式，给人们的生活带来更多的便利与舒适。",
        },
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
// 导出路由实例
export default router;
