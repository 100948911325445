<template>
  <div class="bottom_div_cla">
    <div class="bottom_content_cla">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-image class="img-icon"
            :src="'https://www.aigehui028.com/static/mp/images/agh-img/index/logo_bottom.png'"></el-image>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla">关于我们</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/aboutUs')">公司简介</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/aboutUs')">企业文化</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/aboutUs')">团队背景</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/aboutUs')">企业荣誉</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla">产品系列</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/bed')">共享陪护床</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/bedside')">共享床头柜</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/wheelchair')">共享轮椅</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla" @click="open('https://www.aigehui.com/hospital')">案例医院</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/cityPartner')">城市合伙人</div>
          <div class="menu_second_cla">服务必读</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla">客户中心</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/contactUs')">联系我们</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/freePlacement')">预算收益</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/index')">返回首页</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla" @click="open('https://www.aigehui.com/newsInformation')">新闻资讯</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/newsInformation')">新发资讯</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/newsInformation')">热门资讯</div>
          <div class="menu_second_cla" @click="open('https://www.aigehui.com/newsInformation')">常见问题</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla">友情链接</div>
          <div class="menu_second_cla"
            @click="open('https://www.douyin.com/user/MS4wLjABAAAAKGxHMKe5MGT41DpJMYtC3Yu3g5iUPnvilNAiNLY-oK4')">抖音
          </div>
          <div class="menu_second_cla"
            @click="open('https://author.baidu.com/home?from=bjh_article&app_id=1694277138973195')">百家号</div>
          <div class="menu_second_cla"
            @click="open('https://www.toutiao.com/c/user/token/MS4wLjABAAAAfeu3tgYm2JkN_Nzknccaf3dIBcrbTU607pRwHpZzMD-7Spcrs5YqhVahx57HmwYA/?source=tuwen_detail&log_from=3f490f6b92c8c_1709261713132')">
            今日头条</div>
          <div class="menu_second_cla" @click="open('https://www.163.com/dy/media/T1651845461862.html')">
            网易</div>
          <div class="menu_second_cla"
            @click="open('https://mp.sohu.com/profile?xpt=ZTkxNTA5ZTgtZWQ4MC00ZWZlLWE3MGUtNzg5OTlkMDNhN2Uz&scm=1019.20005.0.0.0&spm=smpc.csrpage.suggest-list.1.1710985547257IWECW6f')">
            搜狐</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla div_content_center">关注我们</div>
          <div class="menu_second_cla div_content_center hvr-grow"
            @click="open('https://www.aigehui028.com/static/mp/images/agh-img/index/gzhewm.jpg')">
            <el-image :src="'https://www.aigehui028.com/static/mp/images/agh-img/index/gzhewm.jpg'"
              class="ewm_img_cla"></el-image>
          </div>
          <div class="menu_second_no_cla div_content_center">关注公众号</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla">&nbsp;</div>
          <div class="menu_second_cla div_content_center hvr-grow"
            @click="open('https://www.aigehui028.com/static/mp/images/agh-img/index/zsewm.jpg')">
            <el-image :src="'https://www.aigehui028.com/static/mp/images/agh-img/index/zsewm.jpg'"
              class="ewm_img_cla"></el-image>
          </div>
          <div class="menu_second_no_cla div_content_center">招商加盟咨询</div>
        </el-col>
        <el-col :span="2">
          <div class="menu_first_cla">&nbsp;</div>
          <div class="menu_second_cla div_content_center hvr-grow"
            @click="open('https://www.aigehui028.com/static/mp/images/agh-img/index/grewm.png')">
            <el-image :src="'https://www.aigehui028.com/static/mp/images/agh-img/index/grewm.png'"
              class="ewm_img_cla"></el-image>
          </div>
          <div class="menu_second_no_cla div_content_center">官方抖音号</div>
        </el-col>
      </el-row>
    </div>
    <div class="zx_content_cla" style="text-align: right;">电话咨询：157-6036-2985（徐经理）</div>
    <div class="zx_content_cla" style="text-align: right;">电话咨询：137-1146-5313（王经理）</div>
    <div class="zx_content_cla">投诉邮箱：458457148@qq.com</div>
    <div class="zx_content_cla fx_content_cla">投资有风险，选择需谨慎</div>
    <div class="bei_cla">四川爱格汇科技集团有限公司-专注共享项目运营十余年 版权所有 <span class="beian"
        @click="open('https://beian.miit.gov.cn/#/Integrated/index')">蜀ICP备19005906号</span></div>
  </div>
</template>

<script>
export default {
  name: "BottomPage",
  data() {
    return {
      activeIndex: '1',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    open(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.bottom_div_cla {
  width: 100%;
  height: 355px;
  padding: 40px;
  background-color: #171C28;
}

.bottom_content_cla {
  width: 80%;
  height: 136px;
  margin-left: 10%;
  padding-bottom: 25px;
}

.img-icon {
  width: 155px;
  height: 58px;
}

.menu_first_cla {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.menu_second_cla {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  cursor: pointer;
  margin-top: 15px;
}

.menu_second_no_cla {
  color: #ffffff;
  font-size: 12px;
}

.menu_second_cla:hover,
.menu_first_cla:hover {
  color: #ffffff;
}

.ewm_img_cla {
  width: 70px;
  width: 70px;
}

.zx_content_cla {
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.fx_content_cla {
  text-align: center;
  padding-bottom: 20px;
}

.bei_cla {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  padding-top: 40px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);

}

.beian {
  cursor: pointer;
}

.beian:hover {
  color: white;
}
</style>