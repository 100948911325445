<template>
  <el-container>
    <!--顶部导航菜单信息-->
    <el-header class="header_cla">
      <top-menu></top-menu>
    </el-header>
    <!--中间实际内容部分-->
    <el-main style="padding: 0;margin: 0;">
      <router-view></router-view>
      <!--底部公共部分信息-->
      <bottom-page></bottom-page>
    </el-main>
  </el-container>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  created() {
    document.title = '爱格汇-共享设备租赁';

  },
  mounted() {
    const setZoom = () => {
      let devicewidth = document.documentElement.clientWidth;
      let scaleWidth = devicewidth / 1920;
      let scale = Math.min(scaleWidth);
      document.body.style.transform = `scaleX(${scale}) scaleY(${scale})`
      document.body.style.transformOrigin = 'top left';
      document.body.style.width = `${1920}px`;
      document.body.style.height = `${1080}px`;
      document.body.style.overflow = 'auto'
      document.body.style.fontSize = `${40 + scale}px`

    };

    setZoom();

    window.onresize = setZoom;
  }
}

</script>
<style scoped>
#app {
  margin: -8px;
}
</style>